'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _phoenix = require('phoenix');

var message = $('.chat-input > input');
var sendButton = $('.chat-input button');
var chatMessages = document.getElementById("recipe-chat-room");
var socket = new _phoenix.Socket("/socket", { params: { token: window.userToken } });
var channel = socket.channel('recipe:' + window.channelID, {});
var renderMessage = function renderMessage(payload) {
  var emptyText = document.getElementById("empty-text");
  if (emptyText) {
    emptyText.parentNode.removeChild(emptyText);
  }

  var template = document.createElement("li");
  template.innerHTML = '<li class="clearfix">\n    <div class="chat-body clearfix">\n      <div class="header">\n        <strong class="primary-font">' + payload.user + '</strong>\n      </div>\n      <p>' + payload.message + '</p>\n    </div>\n  </li>';
  chatMessages.appendChild(template);
  chatMessages.scrollTop = chatMessages.scrollHeight;
};
socket.connect();

// Now that you are connected, you can join channels with a topic:
channel.join().receive("ok", function (messages) {
  messages.forEach(function (message) {
    return renderMessage(message);
  });
}).receive("error", function (reason) {
  return console.log("join failed", reason);
});

message.focus();
message.on('keypress', function (event) {
  if (event.keyCode == 13) {
    channel.push('message:new', { message: message.val() }).receive("ok", function (reply) {
      return console.log("got reply", reply);
    }).receive("error", function (reply) {
      return console.log("got reply", reply);
    });
    message.val("");
  }
});

sendButton.on('click', function (event) {
  var e = $.Event("keypress");
  e.which = 13; //choose the one you want
  e.keyCode = 13;
  message.trigger(e);
});

channel.on('message:new', function (payload) {
  renderMessage(payload);
});

exports.default = socket;
