"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Cart = {
  addHandler: function addHandler(e) {
    e.preventDefault();
    var post_url = $(this).attr("action"); //get form action url
    var form_data = $(this).serialize(); //Encode form elements for submission

    $.post(post_url, form_data, function (response) {
      $.bootstrapGrowl(response.message, {
        offset: { from: 'top', amount: 60 } });
      $(".cart-count").text(response.cart_count);
    });
  }
};

exports.default = Cart;
